import { Boards } from "@air/api";
import { useQuery } from "@tanstack/react-query";
import { useCurrentWorkspace } from "~/providers/CurrentWorkspaceProvider";
export const BOARD_GUESTS = "BOARD_GUESTS";
export const getBoardsGuestsKey = (params)=>{
    return [
        BOARD_GUESTS,
        params
    ];
};
export const useFetchBoardGuests = (boardId)=>{
    const { currentWorkspace } = useCurrentWorkspace();
    const workspaceId = currentWorkspace === null || currentWorkspace === void 0 ? void 0 : currentWorkspace.id;
    return useQuery({
        queryKey: getBoardsGuestsKey({
            boardId,
            workspaceId
        }),
        queryFn: ()=>{
            if (!workspaceId) {
                throw new Error("No workspaceId found");
            }
            return Boards.listGuests({
                boardId,
                workspaceId
            });
        },
        enabled: !!workspaceId
    });
};
