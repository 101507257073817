import { reportErrorToBugsnag } from "@air/utils-error";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useCurrentWorkspace } from "~/providers/CurrentWorkspaceProvider";
import { getBoardsGuestsKey } from "~/swr-hooks/boards/useFetchBoardGuests";
import { sanitizeEmail } from "~/utils/EmailUtils";
import { useAddWorkspaceMembers } from "./useAddWorkspaceMembers";
export const useInviteGuestToBoard = ()=>{
    const { addWorkspaceMember } = useAddWorkspaceMembers();
    const { currentWorkspace } = useCurrentWorkspace();
    const queryClient = useQueryClient();
    const inviteGuestToBoard = useCallback(async (boardId, email, role, members)=>{
        try {
            await addWorkspaceMember([
                {
                    email: sanitizeEmail(email),
                    boardId,
                    roleId: role.id,
                    type: role.type
                }
            ]);
            await queryClient.invalidateQueries({
                queryKey: getBoardsGuestsKey({
                    boardId,
                    workspaceId: currentWorkspace === null || currentWorkspace === void 0 ? void 0 : currentWorkspace.id
                })
            });
        } catch (error) {
            throw reportErrorToBugsnag({
                error,
                context: "Failed to add a board guest",
                metadata: {
                    data: {
                        boardId,
                        email,
                        role,
                        members
                    }
                }
            });
        }
    }, [
        addWorkspaceMember,
        currentWorkspace === null || currentWorkspace === void 0 ? void 0 : currentWorkspace.id,
        queryClient
    ]);
    return {
        inviteGuestToBoard
    };
};
