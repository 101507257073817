import { useTrackClickedEditCustomField } from "@air/analytics";
import { useAirModal } from "@air/provider-modal";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { CustomFieldsEditModal } from "~/components/CustomFields/CustomFieldsEditModal";
import { getCustomFieldOptionsKey, getPrivateBoardCustomFieldsKey } from "~/constants/react-query-keys";
import { removeFieldFromConfigurableCurrentAndSavedView } from "~/store/configViews/actions";
export const useEditBoardCustomField = ()=>{
    const [showCustomFieldEditModal] = useAirModal(CustomFieldsEditModal);
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { trackClickedEditCustomField } = useTrackClickedEditCustomField();
    const editBoardCustomField = useCallback((param)=>{
        let { boardId, trackingLocation, field } = param;
        const fieldKey = getCustomFieldOptionsKey(field.id);
        const allFieldsKey = getPrivateBoardCustomFieldsKey(boardId);
        trackClickedEditCustomField({
            location: trackingLocation,
            type: field.type.title
        });
        showCustomFieldEditModal({
            field,
            trackingLocation,
            onSaveChanges: ()=>{
                queryClient.invalidateQueries({
                    queryKey: fieldKey
                });
                queryClient.invalidateQueries({
                    queryKey: allFieldsKey
                });
            },
            onDelete: ()=>{
                queryClient.invalidateQueries({
                    queryKey: fieldKey
                });
                queryClient.setQueryData(getPrivateBoardCustomFieldsKey(boardId), (fields)=>fields ? {
                        ...fields,
                        data: fields.data.filter((f)=>f.id !== field.id),
                        total: fields.total - 1
                    } : undefined);
                queryClient.invalidateQueries({
                    queryKey: allFieldsKey
                });
                dispatch(removeFieldFromConfigurableCurrentAndSavedView({
                    fieldId: field.id
                }));
            }
        });
    }, [
        dispatch,
        queryClient,
        showCustomFieldEditModal,
        trackClickedEditCustomField
    ]);
    return {
        editBoardCustomField
    };
};
